<template>
 <div >
    <!--篩選區塊-->
    <!--<el-divider content-position="right"> 過濾條件 </el-divider>-->

    <!--
    <el-row>
      <div style="float: right; font-size: 15px; color: blue; solid;">取樣間隔(單位:分鐘) </div>  
      </el-row>
      <el-row>
          <div class="block">
            <el-slider :min="60" :max="1440" input-size="mini" :step="60"
                       v-model.lazy="listQuery.sampleRate"  show-input  >
            </el-slider>
          </div>
    </el-row>
    -->
   
      <el-row>
         
      <div style="text-align: right;">
                                                         <!--替換:日期範圍-->
        <span style="font-weight: bold; font-size: 1em;"> {{$t('Date')+$t('Range')}}：</span>      

        <!--
        <el-date-picker
            v-model="listQuery.updatedate"
            size="small"
            class="filter-item"
            type="date"
            value-format="yyyy-MM-dd" 
            :placeholder="$t('Date')" >     
        </el-date-picker>  -->  
        <!--
         <el-date-picker
            v-model="dateRange"
            size="small"
            class="filter-item"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="listQuery.startOn"
            :end-placeholder="listQuery.endOn">  
          </el-date-picker>  
         -->
          <el-date-picker
            v-model="dateStart"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.startOn">
          </el-date-picker>             
          <el-date-picker
            v-model="dateEnd"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.endOn">
          </el-date-picker>                           
        <!--
         <el-date-picker
            v-model="dateRange"
            size="small"
            class="filter-item"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('Start')+$t('Date')"
            :end-placeholder="$t('End')+$t('Date')">  
          </el-date-picker>   
          -->
        <!--
        <el-input size="small" v-model="listQuery.staionId" placeholder="查詢" class="filter-item" clearable />      -->   
      </div>

    </el-row>

    <el-row>
    <!--本來匯出與報表按鈕是放這邊-->
      
      <!--
      <span v-if="( list!=undefined&&list!=null )" style="font-weight: bold; font-size: 1.2em; float: right;">
          總碳排放量: {{list[0].cabbonTotal}} CO2e
      </span> -->

    </el-row>

    <div style="width:100%; height:280px; left; display: flex;" >

      <div  style="flex:1;">      
        <el-table
          size="mini" stripe height="260"
          :data="list2" style="width: 100%;" :cell-style="{padding: '0', height: '20px'}"
          @sort-change="tableSortChange" :cell-class-name="tableColumnClass"
        >
          <el-table-column align="center" prop="stationName"                       :label="$t('ChargingStation')" /><!--替換:充電站-->
          <el-table-column align="center" prop="power"                             :label="$t('ChargingMeter')" /><!--替換:充電度數-->
          <el-table-column align="center" prop="cabbonNumber"                      :label="$t('CarbonNumber')+$t('Total')" /><!--替換:碳排量總數-->
          <el-table-column align="center" prop="percent"                           :label="$t('CarbonPercentage')" /><!--替換:碳排比-->

        </el-table>
        <!--
        <v-chart 
            :option="usageChart"
            :theme="chartsTheme"
            :update-options="{notMerge: true}"
            style="width: 100%; height:100%;"
        /> -->
      </div>

      <div style="flex:2;">      
      <!--第二個圖渲染-->
      <v-chart 
          :option="carbonChart"
          :theme="chartsTheme"
          :update-options="{notMerge: true}"
          style="width: 100%; height:100%;"
      />      
      </div>
    </div>

    <br> 
      
    <el-row>

      <div style="float: left;">
      <excel-xlsx
        :data="listAll" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xlsx'" :sheet-name="'sheetname'"
        v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"
        >
        <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="logOperate('report','/report/cp','EXPORT','匯出碳排放統計報表報表 xlsx')">.xlsx</el-button> 
      </excel-xlsx>
      </div>  

      <div style="float: left;">
      <excel-xlsx
        :data="listAll" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xls'" :sheet-name="'sheetname'"
        v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"
        >
        <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="logOperate('report','/report/cp','EXPORT','匯出碳排放統計報表報表 xls')">.xls</el-button> 
      </excel-xlsx>
      </div>
      
      <div style="float: left;">  
      <download-excel  type="csv"
                       v-if="listAll!=undefined&&listAll!=null&&listAll.length>0">
           <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="exportToCSV" >.csv</el-button>
      </download-excel>      
      <!--
      <download-excel 
         :data="listAll" :fields="json_fields" :name="filenameAll" type="csv"
         v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"> 
         <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="logOperate('report','/report/cp','EXPORT','匯出碳排放統計報表報表 csv')">{{$t('DownloadAllDataCsv')}}</el-button> 
      </download-excel> -->
      </div>    

      <div style="float: right;">
                                                                <!--替換:充電站選擇-->
        <el-select v-model="listQuery.stationId" :placeholder="$t('ChargingStationS')+'('+$t('All')+')'"
            clearable size="small" class="filter-item-mid" width="140px">
            <!--
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName + ' (id:' + item.stationId + ')'" :value="item.stationId" />-->
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName" :value="item.stationId" />
        </el-select>
   
        <el-select v-model="listQuery.chargepointId" :placeholder="$t('ChargingPointS')+'('+$t('All')+')'"
            clearable size="small" class="filter-item-mid" width="140px">
            <!--
          <el-option v-for="item in chargeOption"  :key="item.pointId"
                    :label="item.pointName + ' (id:' + item.pointId + ')'" :value="item.pointId" />-->
          <el-option v-for="item in chargeOption"  :key="item.pointId"
                    :label="item.pointName" :value="item.pointId" />
        </el-select>

        <!-- 充電槍選擇 -->
        <!--
        <el-select v-model="listQuery.connectorId" :placeholder="$t('ChargingConnectorS')+'('+$t('All')+')'"
            clearable size="small" class="filter-item-mid" width="140px">
          <el-option v-for="item in connectorOption"  :key="item.connectorId"
                    :label="item.connectorName" :value="item.connectorId" />
        </el-select> -->

        <el-button style="float:right;" 
                  type="primary" 
                  size="small" 
                  icon="el-icon-s-data" 
                  v-if="(listAll==undefined||listAll==null||listAll.length<=0)"
                  @click="openFullScreen()" >{{ $t('Query')+$t('Detail') }} {{$t('And')}} {{$t('ExportReport')}}
        </el-button>
                                            <!--查詢明細與匯出報表-->              
      </div>

    </el-row>

    <span v-if="listAll!=undefined&&listAll!=null&&listAll.length>0" style="font-weight: bold; font-size: 1em; float: left;">
        {{$t('ReportProductionTime')}}: {{reportTime}} 
    </span>
        <!--報表產製時間-->

    <!-- table -->
    <el-table v-if="chartDataExist"
      size="mini" stripe height="290"
      v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '20px'}"
      @sort-change="tableSortChange" :cell-class-name="tableColumnClass"
    >

      <el-table-column align="center" prop="stationName"     min-width="60px"  :label="$t('ChargingStation')" /><!--替換:充電站-->
      <el-table-column align="center" prop="pointName"       min-width="100px"  :label="$t('ChargingPoint')" /><!--替換:充電樁-->
      <el-table-column align="center" prop="connectorId"     min-width="60px"  :label="$t('ChargingConnector')" /><!--替換:充電槍-->
      <el-table-column align="center" prop="chargetime"                        :label="$t('ChargingTime')" /><!--替換:充電時間-->
      <el-table-column align="center" prop="power"                             :label="$t('ChargingMeter')"  /><!--替換:充電度數-->
      <el-table-column align="center" prop="factor"                            :label="$t('CarbonFactor')"  /><!--替換:碳排係數-->
      <el-table-column align="center" prop="cabbonNumber"                      :label="$t('CarbonNumber')" /><!--替換:碳排量-->
      <el-table-column align="center" prop="percent"                           :label="$t('CarbonPercentage')"  /><!--替換:碳排比-->
      <el-table-column align="center" prop="createOn"                          :label="$t('Create')+$t('Time')" /><!--替換:建立時間-->

    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" 
                v-if="chartDataExist"
              :size.sync="listQuery.size" @pagination="getList" style="margin: 15px;"/>
   
  </div>
</template>

<style>
.el-table .color-column {
  background:'#6A6AFF' ;
}
</style>

<script>
import * as echarts from "echarts"; // v5.0以上的版本要用此  不可以刪除

import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';

/* 每隔10min刷新出jwt token*/
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import * as main from "@/utils/main";
import {apiUrl} from "@/utils/main";
const authUrl = main.authUrl();
const refreshTokenUrl = authUrl + "refreshToken";
const refreshTokenInterval = 10*60*1000;     // 10分鐘

export default {
    name: 'Carbon',
    components: {
        Pagination,
    },
    mixins: [mixins],
    data() {
        return {
            dialogWidth     : 0,
            total           : 0,
            stationOption    : undefined,
            chargeOption     : undefined,
            connectorOption  : undefined,
            list            : [],
            listCsv         : [],
            list2           : [],
            listSum         : undefined,
            listAll         : undefined,            
            json_fields     : {
              "Station(id)"      : "stationID",    //CSV只允許英文
              "Charge Point(id)" : "chargeID",
              "Connector"    : "connectorId",
              "Charge Time"  : "chargetime",
              "Power Degree" : "power",
              "Carbon Factor": "factor",
              "Carbon Number": "cabbonNumber",
              "CarbonPercentage": "percent",
              "Create Time"     : "createOn"
            },
            json_meta: [
              [ {
                  " key "    : " charset ",
                  " value "  : " utf- 8 "
                } ]
            ],
            xlsxColumns      : [
                {
                    label: this.$t('ChargingStation'),
                    field: "stationName",
                },
                {
                    label: this.$t('ChargingPoint'),
                    field: "pointName",
                },
                {
                    label: this.$t('ChargingConnector'),
                    field: "connectorId",
                },
                {
                    label: this.$t('ChargingTime'),
                    field: "chargetime",
                },
                {
                    label: this.$t('ChargingMeter'),
                    field: "power",
                },
                {
                    label: this.$t('CarbonFactor'),
                    field: "factor",
                },
                {
                    label: this.$t('CarbonNumber'),
                    field: "cabbonNumber",
                },
                {
                    label: this.$t('CarbonPercentage'),
                    field: "percent",
                },
                {
                    label: this.$t('Create') + this.$t('Time'),
                    field: "createOn",
                },
                ],
            /* 匯出檔名 */
            reportTime     : null,
            filenameCsv    : this.$moment().format("YYYYMMDD-HHmmss").toString() + "_CarbonReport" + ".xls",
            filenameAll    : this.$moment().format("YYYYMMDD-HHmmss").toString() + "_CarbonReportAll" + ".xls",
            filenameAllXlsx: this.$moment().format("YYYYMMDD-HHmmss").toString() + "_CarbonReportAll" ,
            listLoading    : false,

            listQuery: { 
                //serviceId: this.$store.state.currentAccount.id,  
                serviceId        :  this.$store.state.currentAccount.serviceInfo.id,
                stationId        :  undefined,  
                chargepointId    :  undefined,
                connectorId      :  undefined,
                startOn          :  this.$moment().subtract(8, "days").format("YYYY-MM-DD"),//7天前,
                endOn            :  this.$moment().subtract(1, "days").format("YYYY-MM-DD"),//1天前
                page             : 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                size             : 15,
                sort             : 'cabbonNumber,DESC',
            },

            list2Query:{
                serviceId        :  this.$store.state.currentAccount.serviceInfo.id,
                stationId        :  undefined,  
                startOn          :  this.$moment().subtract(8, "days").format("YYYY-MM-DD"),//7天前,
                endOn            :  this.$moment().subtract(1, "days").format("YYYY-MM-DD"),//1天前
                page             : 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                size             : 15,
                sort             : 'cabbonNumber,DESC',              
            },

            paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
            //dateRange      : undefined,
            dateStart        : undefined,
            dateEnd          : undefined,

            dialogSettingVisible: false,

            timer          : undefined,
            loading        : undefined,
            size           : undefined,
            chartDataExist : false, // 是否有圖表資料
            xAxisData      : [],    // x軸 日期時間
            yAxisData      : [],    // y軸  
            y2AxisData     : [],    // y軸2             
        };
    },
  async created() {
    console.log("[list]=",this.list);
    console.log("[listAll]=",this.listAll);
    this.dialogWidth = this.setDialogWidth();
    this.getStationByServiceId();
    this.resetPage(this.getchart); 

    //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    //定時刷新
    this.id_setInterval = window.setInterval(async function () {
      try{
        const response = await refreshToken(refreshTokenUrl);
        console.log("auth/refreshToken success! , id_setInternal="+this.id_setInterval);
        sessionStorage.setItem('csrf_token', response.data.data);
      }catch (error){
        console.log("delete company error");
        console.log(error);
      }
    }, refreshTokenInterval);
  },
  beforeDestroy() {
    
    clearTimeout( this.timer );

    //在切換Component之前，將refresh token的setInterval timeout 給移除
    if(this.id_setInterval !== null)
    {
      console.log('beforeUnmount is called, clear the timout id='+this.id_setInterval);
      window.clearInterval(this.id_setInterval);
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },    
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
    '$i18n.locale': function(val) {
      console.log('Role組件重載前')
      this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
      console.log('Role組件重載完成')
      },
    /* ----------------------------------------------------------------------------- */   
    'listQuery.stationId': function (val) {
      this.listQuery.page = 0;
      this.listQuery.chargepointId = undefined;
      this.listAll= [];
      //this.list2  = [];
      this.listSum= null;
      this.chartDataExist = false;
      this.chargeOption=null;
      /* 若充電站有被選擇,則才會找該充電站所屬的充電樁,沒被選擇則stationId為0 */
      if( this.listQuery.stationId != null && this.listQuery.stationId != undefined && this.listQuery.stationId > 0 ) {
        this.axios.get("/api/v1/carbon/pointidListByserviceIdandstationId?serviceId="+this.listQuery.serviceId+"&stationId="+val).then(
            res => {
            console.log("Request:pointidListByserviceIdandstationId [res.data]",res.data);
            this.chargeOption=res.data;
            }
            );
      } else this.listQuery.stationId = undefined;
      //this.resetPage(this.getchart);
     },
    'listQuery.chargepointId': function (val) {
      this.listQuery.page = 0;
      this.listQuery.connectorId = undefined;
      this.listAll= [];
      //this.list2  = [];
      this.listSum= null;
      this.chartDataExist = false;
      this.connectorOption=null;
      /* 若充電樁有被選擇,則才會找該充電樁所屬的充電槍,沒被選擇則chargepointId為"" */
      if( this.listQuery.chargepointId != null && this.listQuery.chargepointId != undefined && this.listQuery.chargepointId != "" ) {
        this.axios.get("/api/v1/carbon/connectoridListBy3Id?serviceId="+this.listQuery.serviceId+"&stationId="+this.listQuery.stationId+"&chargepointId="+val).then(
            res => {
            console.log("Request:connectoridListBy3Id [res.data]",res.data);
            this.connectorOption=res.data;
            }
            );
      } else this.listQuery.chargepointId = undefined;
      //this.resetPage(this.getchart);
     },
    'listQuery.connectorId': function (val) {
      this.listQuery.page = 0;
      this.listAll= [];
      //this.list2  = [];
      this.listSum= null;
      this.chartDataExist = false;
      //this.connectorOption=null;
      if( this.listQuery.connectorId != null && this.listQuery.connectorId != undefined && this.listQuery.connectorId != "" ) {
        //this.resetPage(this.getchart);      
      } else this.listQuery.connectorId = undefined;
     },        
        /*
    'listQuery.updatedate': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)      
      this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },  
    }, */  
    /*
    dateRange: function(val) {
      if (val) {
        this.listQuery.startOn = this.dateRange[0]
        this.listQuery.endOn   = this.dateRange[1]
      } else {
        this.listQuery.startOn = undefined
        this.listQuery.endOn   = undefined
      }
        this.listAll= [];
        this.list2  = [];
        this.listSum= null;
        this.chartDataExist = false;
        this.resetPage(this.getchart);      
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },*/
    dateStart: function(val) {
      if (val) {
        this.listQuery.startOn = this.dateStart
      } else {
        this.listQuery.startOn = undefined
      }
        this.listAll= [];
        this.list2  = [];
        this.listSum= null;
        this.chartDataExist = false;
        this.resetPage(this.getchart);      
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },   
    dateEnd: function(val) {
      if (val) {
        this.listQuery.endOn = this.dateEnd
      } else {
        this.listQuery.endtOn = undefined
      }
        this.listAll= [];
        this.list2  = [];
        this.listSum= null;
        this.chartDataExist = false;
        this.resetPage(this.getchart);      
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },           
  },

  computed: {
    usageChart() {
        let _this = this  
        let option = {     
          tooltip: {
            trigger: "axis",              
          }, 
          legend: {              //顯示該圖表資訊      
            data: ['各站排名列表'],   //區間電量
            //bottom :1            //文字置底
          },        
          xAxis: {        
            type: 'category',  
            data: _this.xAxisData
          },
          yAxis: {
            type: 'value',
            name: '【交接提醒】Y軸',
            nameTextStyle:{
              color:'Indigo', 
              //fontSize:12,  
            },
            data: _this.yAxisData        
          },                  
          series: [
            {
              data: _this.yAxisData, //圖表的數據
              type: 'line',      //圖表類型        
              symbol: 'none',
              name:'【交接提醒】這要改排名表',  
              color:'Indigo',
              stack: true,      
            },                         
          ]
        }
        return option
      },
    carbonChart() {
        let _this = this  
        let option = {     
          tooltip: {
            trigger: "axis",              
          }, 
          legend: {              //顯示該圖表資訊      
            data: ['各站碳排放量'],  
            //bottom :1            //文字置底
          },        
          xAxis: {        
            type: 'category',  
            data: _this.xAxisData
          },
          yAxis:[ /*
           {
            type: 'value',
            name: '充電度數',
            nameTextStyle:{
              color:'DarkSlateGray', 
              //fontSize:12,  
            },             
            data: _this.yAxisData        
            },      */      
            {
            type: 'value',
            name: this.$t('CarbonNumber')+this.$t('Total'),//'碳排放量總數'
            nameTextStyle:{
              color:'DarkSlateGray', 
              //fontSize:12,  
            },             
            data: _this.y2AxisData        
           },
          ],                  
          series: [
            /*
            {
              data: _this.yAxisData, //圖表的數據
              type: 'bar',      //圖表類型        
              symbol: 'none',
              name:'用電度數',  
              color:'DarkSlateGray',
              //stack: true,      
            },*/              
            {
              data: _this.y2AxisData, //圖表的數據
              type: 'bar',      //圖表類型        
              symbol: 'none',
              name:   this.$t('CarbonNumber'),//'碳排放量'
              color:'DarkSlateGray',
              //stack: true,      
            },
                                     
          ]
        }
        return option
      },      
  },
  methods: {
    exportToCSV() {
      this.logOperate('report','/report/cp','EXPORT','匯出碳排放統計報表報表 csv');
      //const csvContent = "data:text/csv;charset=Big5," + this.convertToCSV(this.listXls); //utf-8 
      const csvContent = this.convertToCSV(this.listCsv); //utf-8 
      //const csvContent = new Blob('\ufeff${csv}', {type: 'text/plain;charset=utf-8'})+ this.convertToCSV(data);
      //const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      //link.setAttribute("href", encodedUri);
      //link.setAttribute("download", "data.csv");
      //document.body.appendChild(link);
      //link.click();

      // "\ufeff"是为了解决CSV中文
      let blob = new Blob(["\ufeff" +csvContent],{type: 'text/csv;charset=utf-8;'});
      let url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', this.filenameAllXlsx);
      link.click();
    },
    convertToCSV(data) {
      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(data[0]);

      let result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      data.forEach(item => {
        let row = "";
        keys.forEach(key => {
          row += item[key] + columnDelimiter;
        });
        result += row.slice(0, -1);
        result += lineDelimiter;
      });

      return result;
    },
    tableColumnClass({row,column,rowIndex,columnIndex}) {
       if(columnIndex === 1) { return 'color-column'; }
       else  return '';
    },
    /* 產生loading圖示 */
    openFullScreen(){
      this.loading = this.$loading(
        {
          lock: true,
          text: '報表產生中',
          spinner: 'el-icon-loading',
          background: 'rgba(0,0,0,0.7)',
        }
      );
      this.timer = setTimeout(
        ()=>{this.getList();},
        1500
      );  
    },
    getStationByServiceId() { 
      console.log("GET:/api/v1/carbon/stationidListByserviceId?serviceId="+this.listQuery.serviceId);
      this.axios.get("/api/v1/carbon/stationidListByserviceId?serviceId="+this.listQuery.serviceId).then(
          res => {
          console.log("Response:stationidListByserviceId [res.data]",res.data);
          this.stationOption=res.data;
          }
         );
    },       
    async getList() {

      if( (this.listQuery.startOn==null) || (this.listQuery.endOn==null) ) {
        this.listQuery.startOn = this.$moment().subtract(8, "days").format("YYYY-MM-DD");//7天前,
        this.listQuery.endOn   = this.$moment().subtract(1, "days").format("YYYY-MM-DD");//1天前
      }

      this.chartDataExist = false
      this.listLoading = true;
      console.log("GET:/api/v1/carbon/carbonLog:" + JSON.stringify(this.listQuery));

      await this.axios.get('/api/v1/carbon/carbonLog', { params:  this.listQuery }).then( 
        res=> {
                console.log("Response:carbonLog [res.data]",res.data)
                this.list = res.data.content
                this.total = res.data.totalElements       
              },
        error => {
                return alert("error!!");
              }
        )

      this.size          = this.listQuery.size
      this.listQuery.page=0
      this.listQuery.size=10000

      /* 產生報表所需之資料 */
      await this.axios.get('/api/v1/carbon/carbonLog', {params: this.listQuery}).then(res=> {
        this.listAll = res.data.content
        this.listCsv = this.listAll.map(( item , index ) => {
          //item.no = index + number*size +1
          if(this.$i18n.locale == 'tw'){
            return {
              '充電站':   item.stationName,
              '充電樁':   item.pointName,
              '充電槍':   item.connectorId,
              '充電時間': item.chargetime,
              '充電度數': item.fpower,
              '碳排係數': item.factor,
              '碳排放量': item.cabbonNumber,
              '碳排比':   item.percent,
              '建立時間': item.createOn
            }
          } else {
            return {
              'Charging Station':   item.stationName,
              'Charging Point':   item.pointName,
              'Charging Connector':   item.connectorId,
              'Charging Time': item.chargetime,
              'Charging Meter': item.fpower,
              'Carbon Factor': item.factor,
              'Carbon Number': item.cabbonNumber,
              'Carbon Percentage':   item.percent,
              'Create Time': item.createOn
            }          
          }     
        })
      })  
      var thisTime  =    this.$moment()
      this.reportTime  = thisTime.format("YYYY/MM/DD HH:mm:ss").toString()
      this.filenameCsv =    thisTime.format("YYYYMMDD-HHmmss").toString() + "_CarbonReport" + ".xls"
      this.filenameAll =    thisTime.format("YYYYMMDD-HHmmss").toString() + "_CarbonReportAll" + ".xls"
      this.filenameAllXlsx= thisTime.format("YYYYMMDD-HHmmss").toString() + "_CarbonReportAll" 
      /* 產生報表所需之資料 */          
      this.listQuery.size=this.size
      this.chartDataExist = true 
      /*
      console.log("GET:/api/v1/carbon/carbonLog:"+this.listQuery);
      await this.axios.get('/api/v1/billingquery/httpget2total', { params:  this.listQuery }).then(
        res=> {
                console.log("Response://api/v1/billingquery/httpget2total",res.data)
                this.listSum = res.data
              },
        error => {
                return alert("忘了選擇日期!!");
              }              
        )
      */
      
      this.listLoading = false;
      this.loadingClose();

    },
    async getchart() {

      if( (this.listQuery.startOn==null) || (this.listQuery.endOn==null) ) {
        this.listQuery.startOn = this.$moment().subtract(8, "days").format("YYYY-MM-DD");//7天前,
        this.listQuery.endOn   = this.$moment().subtract(1, "days").format("YYYY-MM-DD");//1天前
      }

      this.xAxisData= []
      this.yAxisData= []
      this.y2AxisData= []      
      
      /* 產生列表2與圖表所需之資料 */
      this.list2Query.serviceId = this.listQuery.serviceId
      this.list2Query.stationId = this.listQuery.stationId
      this.list2Query.startOn   = this.listQuery.startOn
      this.list2Query.endOn     = this.listQuery.endOn
      console.log("GET:/api/v1/carbon/carboncountLog:" + JSON.stringify(this.list2Query));

      await this.axios.get('/api/v1/carbon/carboncountLog', {params: this.list2Query}).then(res=> {
        this.list2 = res.data.content
        console.log("Response:carboncountLog [res.data.content]",res.data.content)
        res.data.content.forEach(e =>
        {
          this.xAxisData.push(e.stationName )
          this.yAxisData.push(e.power )                    
          this.y2AxisData.push(e.cabbonNumber )
        })
      })
      /* 產生列表2與圖表所需之資料 */
    },
    /* 關閉loading圖示 */
    loadingClose(){
      this.loading.close();
    },
    handleRowClick(row, column, event) {
    },
  },
}

</script>
